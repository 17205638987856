export default class Toggle {
  constructor() {
    this.init();
  }

  init() {
    $(".js-toggleTitle").on("click", (e) => {
      const title = $(e.currentTarget);
      if ($(title).hasClass("active")) {
        this.hideToggle(title);
      } else {
        this.showToggle(title);
      }
    });
  }

  hideToggle(title) {
    const text = $(title).parents(".js-toggleContainer").find(".js-toggleText");
    $(text)
      .slideUp(300)
      .animate(
        { opacity: 0 },
        {
          queue: false,
          duration: 300,
          complete: () => {
            $(text).removeAttr("style");
            $(title).removeClass("active");
          },
        }
      );
  }

  showToggle(title) {
    const text = $(title).parents(".js-toggleContainer").find(".js-toggleText");
    const container = $(title).parents(".js-toggleSection");
    const toggles = $(container).find(".js-toggleTitle").not(title);
    $(toggles).each((i, el) => {
      this.hideToggle(el);
    });
    $(text)
      .css({ opacity: 0 })
      .slideDown(300)
      .animate(
        { opacity: 1 },
        {
          queue: false,
          duration: 300,
          complete: () => {
            $(title).addClass("active");
          },
        }
      );
  }
}
