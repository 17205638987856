export default class Tickets {
  constructor() {
    this.init();
  }

  init() {
    $(".js-ticketEvent").on("click", (e) => {
      const item = $(e.currentTarget).attr("href");
      plausible("Clic sur bouton Billetterie", { props: { target: item } });
    });
  }
}
