import "./_lib/modernizr";
import "../scss/application.scss";
import jquery from "jquery";

window.$ = window.jQuery = jquery;

import Website from "./_modules/website";
import Header from "./_modules/header";
import Toggle from "./_modules/toggle";
import Slideshow from "./_modules/slideshow";
import Fixed from "./_modules/fixed";
import Readmore from "./_modules/readmore";
import Marquee from "./_modules/marquee";
import Filter from "./_modules/filter";
import Player from "./_modules/player";
import Ajax from "./_modules/ajax";
import Newsletter from "./_modules/newsletter";
import Cursor from "./_modules/cursor";
import Variables from "./_modules/variables";
import Lazy from "./_modules/lazy";
import Tickets from "./_modules/tickets";
import Mail from "./_modules/mail";

$(function () {
  new Variables();
  new Website();
  new Lazy();
  new Cursor();
  new Header();
  new Toggle();
  new Slideshow();
  new Fixed();
  new Readmore();
  new Filter();
  new Player();
  new Ajax();
  new Newsletter();
  new Tickets();
  new Mail();

  $(".js-marquee").each((i, el) => {
    new Marquee(el);
  });
});
