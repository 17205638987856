export default class Cursor {
  constructor() {
    this.cursorHeight = 16;
    this.cursorHeightBig = 32;
    this.hoveredElement = null;

    this.initMouse();
    cancelAnimationFrame(window.cursorRAF);
    window.cursorRAF = requestAnimationFrame((time) => this.update(time));
  }

  update(time) {
    window.cursorRAF = requestAnimationFrame((time) => this.update(time));
    this.updateCursor(time);
  }

  initMouse() {
    $(document).on("mousemove", (e) => {
      $(".js-cursor").css({ opacity: 1 });
      this.setMousePosition(e);
    });
  }

  checkHoverElement() {
    if ($("html").hasClass("no-touch")) {
      const hoveredElement = document.elementFromPoint(window.mousePosition.x, window.mousePosition.y);
      const correctedHoveredElement = this.getCorrectedHoveredElement(hoveredElement);
      if (correctedHoveredElement != this.hoveredElement) {
        const prev = this.hoveredElement;
        const next = correctedHoveredElement;
        this.callMouseLeaveFunction(prev);
        this.callMouseEnterFunction(next);
        this.hoveredElement = correctedHoveredElement;
      }
    }
  }

  callMouseLeaveFunction(el) {
    if (window.cursorStyle != null) window.hoverContext = null;
    if ($(el).hasClass("js-siteTitle")) $(".js-siteTitle").removeClass("hovering");
    window.cursorStyle = null;
    this.mouseLeave(el);
  }

  callMouseEnterFunction(el) {
    if ($(el).find("iframe").length) {
      window.cursorStyle = $(el).hasClass("js-imageToZoom") ? "iframe" : "next";
    } else if ($(el).hasClass("js-hoverTag")) {
      if (!$(el).hasClass("selected")) {
        const item = $(el).children().first();
        this.mouseEnter(item, null, null, true);
      }
      if ($(el).hasClass("js-siteTitle")) $(".js-siteTitle").addClass("hovering");
      window.cursorStyle = null;
    } else if ($(el).hasClass("js-hoverLink")) {
      if (!$(el).hasClass("active")) {
        const radius = this.cursorHeightBig / 2;
        const rect = {
          width: this.cursorHeightBig,
          height: this.cursorHeightBig,
        };
        this.mouseEnter(el, radius, rect, false);
      }
      window.cursorStyle = null;
    } else if ($(el).hasClass("js-slide")) {
      const container = $(el).parents(".js-fixedContainer");
      if ($(container).hasClass("active") || $(container).hasClass("open")) {
        window.cursorStyle = $(el).hasClass("js-imageToZoom") ? "zoom-in" : "next";
      } else {
        window.cursorStyle = null;
      }
    } else if ($(el).hasClass("js-zoom") || $(el).hasClass("js-fixedContainerOut")) {
      window.cursorStyle = "zoom-out";
    } else if ($(el).hasClass("js-searchInput")) {
      window.cursorStyle = "search";
    }

    if (window.cursorStyle) {
      window.hoverContext = {
        item: null,
        radius: 0,
        rect: { width: 0, height: 0 },
      };
      $(".js-cursor").attr("data-style", window.cursorStyle);
    } else {
      $(".js-cursor").attr("data-style", "");
    }
  }

  getCorrectedHoveredElement(hoveredElement) {
    const arr = [];
    const arrOfClasses = ["js-hoverTag", "js-hoverLink", "js-slide", "js-zoom", "js-fixedContainerOut", "js-searchInput"];
    arrOfClasses.forEach((possibleClass) => {
      if ($(hoveredElement).hasClass(possibleClass)) {
        arr.push($(hoveredElement)[0]);
      } else if ($(hoveredElement).closest("." + possibleClass).length) {
        arr.push($(hoveredElement).closest("." + possibleClass)[0]);
      } else {
        arr.push(false);
      }
    });
    const index = arr.findIndex((el) => el != false);
    if (index >= 0) return arr[index];
    return null;
  }

  mouseEnter(item, radius = this.cursorHeight / 2, rect = { width: this.cursorHeight, height: this.cursorHeight }, addItemToHoverContext = false) {
    window.hoverContext = addItemToHoverContext ? { item, radius: null, rect: null } : { item: null, radius, rect };
    this.startTransitioning();
    $(item).addClass("hovered");
  }

  mouseLeave(item) {
    window.hoverContext = null;
    this.startTransitioning();
    $(".hovered").removeClass("hovered");
  }

  updateCursor(time) {
    if (window.transitioning && window.transitioning.time + 300 < time) window.transitioning = null;

    this.checkHoverElement();

    if (window.hoverContext && window.hoverContext.item) {
      window.hoverContext.rect = $(window.hoverContext.item)[0].getBoundingClientRect();
      window.hoverContext.radius = parseFloat(this.getStyleObject(window.hoverContext.item).borderTopLeftRadius.replace("px", ""));
    }

    const rect = window.hoverContext ? window.hoverContext.rect : null;
    const rad = window.hoverContext ? window.hoverContext.radius : null;
    const centerX = window.hoverContext && rect.left != null ? rect.left + rect.width / 2 : null;
    const centerY = window.hoverContext && rect.left != null ? rect.top + rect.height / 2 : null;

    let x = centerX != null ? centerX : window.mousePosition.x;
    let y = centerY != null ? centerY : window.mousePosition.y;
    let width = rect && rect.width != null ? rect.width : this.cursorHeight;
    let height = rect && rect.height != null ? rect.height : this.cursorHeight;
    let radius = rad != null ? rad : this.cursorHeight / 2;

    if (window.transitioning) {
      const timeFraction = this.ease((time - window.transitioning.time) / 300);
      x = this.lerp(window.transitioning.position.x, x, timeFraction);
      y = this.lerp(window.transitioning.position.y, y, timeFraction);
      width = this.lerp(window.transitioning.rect.width, width, timeFraction);
      height = this.lerp(window.transitioning.rect.height, height, timeFraction);
      radius = this.lerp(window.transitioning.radius, radius, timeFraction);
    }

    $(".js-cursor").css({
      left: x - width / 2,
      top: y - height / 2,
      width: width,
      height: height,
      borderRadius: radius,
    });

    window.cursorPosition = { x, y };
  }

  startTransitioning() {
    window.transitioning = {
      position: { ...window.cursorPosition },
      time: performance.now(),
      rect: $(".js-cursor")[0].getBoundingClientRect(),
      radius: $(".js-cursor")[0].getBoundingClientRect().height / 2,
    };
  }

  ease(t) {
    return 1 - Math.pow(1 - t, 5);
  }

  lerp(a, b, n) {
    return (1 - n) * a + n * b;
  }

  setMousePosition(e) {
    window.mousePosition.x = e.clientX;
    window.mousePosition.y = e.clientY;
  }

  getStyleObject(el) {
    var dom = $(el)[0];
    var style;
    var returns = {};
    if (window.getComputedStyle) {
      var camelize = function (a, b) {
        return b.toUpperCase();
      };
      style = window.getComputedStyle(dom, null);
      for (var i = 0; i < style.length; i++) {
        var prop = style[i];
        var camel = prop.replace(/\-([a-z])/g, camelize);
        var val = style.getPropertyValue(prop);
        returns[camel] = val;
      }
      return returns;
    }
    if (dom.currentStyle) {
      style = dom.currentStyle;
      for (var prop in style) {
        returns[prop] = style[prop];
      }
      return returns;
    }
    return $(el).css();
  }
}
