export default class Filter {
  constructor() {
    this.delay = 80;
    this.future = null;
    this.filters = [];
    this.initFiltersMobile();
    this.initFilters();
    this.initEvents();
    this.initClick();
  }

  initFiltersMobile() {
    $(".js-filterGroup").on("click", (e) => {
      const item = $(e.currentTarget).parent();
      if ($(item).hasClass("open")) {
        this.hideFilters(item);
      } else {
        $(".js-filtersItem")
          .not(item)
          .each((i, el) => {
            this.hideFilters(el);
          });
        this.showFilters(item);
      }
    });
  }

  showFilters(element) {
    const sub = $(element).children(".js-filter");
    const length = $(sub).length;
    if (length > 0) {
      this.hoverEventAllowed = false;
      $(element).addClass("open");
      $(sub).each((i, el) => {
        setTimeout(() => {
          $(el).addClass("active");
          if (i == length - 1) this.hoverEventAllowed = true;
        }, this.delay * i);
      });
    }
  }

  hideFilters(element) {
    this.hoverEventAllowed = false;
    const length = $(element).find(".js-filter.active").length;
    $(element).removeClass("open");
    $($(element).find(".js-filter.active").get().reverse()).each((i, el) => {
      setTimeout(() => {
        $(el).removeClass("active");
        if (i == length - 1) this.hoverEventAllowed = true;
      }, this.delay * i);
    });
  }

  initFilters() {
    $(".js-filter.selected").each((i, el) => {
      this.addToFilterArr(el);
    });
  }

  initEvents() {
    this.current = $(".js-eventBlock")
      .not(".hidden")
      .map((i, el) => $(el).attr("id"))
      .get();
  }

  initClick() {
    $(".js-filter").on("click", (e) => {
      if ($(e.currentTarget).hasClass("selected")) {
        this.removeFilter(e.currentTarget);
      } else {
        this.addFilter(e.currentTarget);
        plausible("Clic sur filtre", { props: { filter: $(e.currentTarget).text() } });
      }
    });

    $(".js-filtersReset").on("click", (e) => {
      $(".js-filter.selected").each((i, el) => this.removeFilter(el));
    });
  }

  createGroup(group, filter) {
    this.filters.push({
      name: group,
      filters: [filter],
    });
  }

  addToFilterArr(el) {
    const group = $(el).data("group");
    const filter = $(el).data("filter");
    const groupInArr = this.filters.find((el) => el.name == group);
    if (groupInArr === undefined) {
      this.createGroup(group, filter);
    } else {
      groupInArr["filters"].push(filter);
    }
  }

  addFilter(el) {
    this.addToFilterArr(el);
    this.filterGrid();
    $(el).addClass("selected");
  }

  removeFilter(el) {
    const group = $(el).data("group");
    const filter = $(el).data("filter");
    const groupInArr = this.filters.find((el) => el.name == group);
    groupInArr["filters"] = groupInArr["filters"].filter((item) => item !== filter);
    if (groupInArr["filters"].length == 0) this.filters = this.filters.filter((item) => item !== groupInArr);
    this.filterGrid();
    $(el).removeClass("selected");
  }

  filterGrid() {
    this.future = $(".js-eventBlock")
      .filter((i, el) => {
        const filters = $(el).data("filters").split(" ");
        const arr = this.filters.map((f) => {
          return filters.find((val) => f.filters.includes(val)) ? true : false;
        });
        return arr.every(Boolean);
      })
      .map((i, el) => $(el).attr("id"))
      .get();
    const toShow = this.future.filter((x) => !this.current.includes(x));
    const toHide = this.current.filter((x) => !this.future.includes(x));
    this.hideElements(toHide);
    this.showElements(toShow, toHide.length * this.delay);
    this.createURL();
    this.current = this.future;
  }

  hideElements(elements) {
    const blocks = elements.map((i) => $(".js-eventBlock#" + i));
    $(blocks).each((i, el) => {
      setTimeout(() => {
        $(el).addClass("hidden");
      }, this.delay * i);
    });
  }

  showElements(elements, delay) {
    const blocks = elements.map((i) => $(".js-eventBlock#" + i));
    $(blocks).each((i, el) => {
      setTimeout(() => {
        $(el).removeClass("hidden");
      }, delay + this.delay * i);
    });
  }

  createURL() {
    let obj = this.checkCurrentQuery();
    this.filters.forEach((el) => {
      obj[el["name"]] = el["filters"];
    });
    const searchParams = new URLSearchParams(obj);
    const searchString = decodeURIComponent(searchParams.toString());
    const newUrl = window.location.origin + window.location.pathname + (searchString ? "?" : "") + searchString;
    history.pushState(null, null, newUrl);
  }

  checkCurrentQuery() {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("q")) return { q: searchParams.get("q") };
    return {};
  }
}
