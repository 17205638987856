export default class Readmore {
  constructor() {
    this.init();
  }

  init() {
    $(".js-readmoreButton").on("click", (e) => {
      const button = $(e.currentTarget);
      if ($(button).hasClass("active")) {
        this.hideText(button);
      } else {
        this.showText(button);
      }
    });
  }

  showText(button) {
    const text = $(button).parent().prev();
    $(text)
      .css({ opacity: 0 })
      .slideDown(300)
      .animate(
        { opacity: 1 },
        {
          queue: false,
          duration: 300,
          complete: () => {
            $(button).html("<span>Lire moins</span>").addClass("active");
          },
        }
      );
  }

  hideText(button) {
    const text = $(button).parent().prev();
    $(text)
      .slideUp(300)
      .animate(
        { opacity: 0 },
        {
          queue: false,
          duration: 300,
          complete: () => {
            $(text).removeAttr("style");
            $(button).html("<span>Lire plus</span>").removeClass("active");
          },
        }
      );
  }
}
