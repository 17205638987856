export default class Newsletter {
  constructor() {
    this.init();
  }

  init() {
    $(".js-newsletterForm").on("submit", (e) => {
      e.preventDefault();
      this.process(
        "https://services.sarbacane.com/core/v1/forms/contacts/upsert?listID&#x3D;j9hCvyY4RheSW-nsQrUGGw&amp;formID&#x3D;yJ6h4_3vQwSzk3HuVyZ-2w&amp;timezone&#x3D;Europe/Paris",
        "https://forms.sbc08.com/",
        "5aba185fb85b5350ef1d0796",
        "false",
        "message",
        "",
        "https://services.sarbacane.com/core/v1/transactional/sendmessage/optin",
        "",
        "",
        "Centre Wallonie-Bruxelles",
        "Confirmation de votre inscription",
        "admin",
        "internet@cwb.fr",
        "Merci",
        "Vos informations ont été ajoutées avec succès.",
        "Vous allez recevoir un email",
        "Vous devrez cliquer sur le lien de confirmation pour valider votre inscription",
        "Erreur",
        "Une erreur inattendue s%27est produite.",
        "Le formulaire est en cours d%27édition, veuillez patienter quelques minutes avant d%27essayer à nouveau.",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      );
    });

    $(".js-newsletterCheckbox").each((i, el) => {
      $(el).on("click", (e) => {
        this.updateRequirements($(e.currentTarget).attr("name"));
      });
    });
  }

  process(
    formUrl,
    resUrl,
    accountId,
    actionOptin,
    actionType,
    actionLink,
    formOptinUrl,
    formApiUser,
    formApiKey,
    actionAlias,
    actionSubject,
    actionRecipient,
    actionReply,
    actionContentTitle,
    actionContentMessage,
    actionEmailTitle,
    actionEmailMessage,
    actionErrorTitle,
    actionErrorMessage,
    formEditErrorMessage,
    formOptinTextSubscribe,
    formOptinTextDetails1,
    formOptinTextDetails2,
    formOptinTextContact,
    formOptinTextAntispam,
    formOptinTextAntispamLink,
    formOptinTextAntispamUrl
  ) {
    try {
      var decodedFormUrl = this.htmlDecode(formUrl);
      var timezone_offset = "";
      var timezone_param = "&timezone=";
      var timezone_param_pos = decodedFormUrl.indexOf(timezone_param);
      if (timezone_param_pos !== -1) {
        var timezone_value = decodedFormUrl.substring(timezone_param_pos + timezone_param.length);
        var other_params = timezone_value.indexOf("&");
        if (other_params !== -1) {
          timezone_value = timezone_value.substring(0, other_params);
        }
        if (timezone_value === "") {
          timezone_value = "Europe/Paris";
        }
        var timezone_date = new Date().toLocaleString("fr", { timeZone: timezone_value, timeZoneName: "short" });
        var utc_str = "UTC";
        var utc_pos = timezone_date.indexOf(utc_str);
        if (utc_pos !== -1) {
          timezone_offset = "&amp;timezoneOffset&#x3D;" + timezone_date.substring(utc_pos + utc_str.length);
        }
      }
      formUrl = formUrl + timezone_offset;
    } catch (e) {}
    var query_string = "";
    actionAlias = decodeURIComponent(actionAlias);
    actionSubject = decodeURIComponent(actionSubject);
    actionRecipient = decodeURIComponent(actionRecipient);
    actionReply = decodeURIComponent(actionReply);
    actionContentTitle = decodeURIComponent(actionContentTitle);
    actionContentMessage = decodeURIComponent(actionContentMessage);
    actionEmailTitle = decodeURIComponent(actionEmailTitle);
    actionEmailMessage = decodeURIComponent(actionEmailMessage);
    actionErrorTitle = decodeURIComponent(actionErrorTitle);
    actionErrorMessage = decodeURIComponent(actionErrorMessage);
    formEditErrorMessage = decodeURIComponent(formEditErrorMessage);
    formOptinTextSubscribe = decodeURIComponent(formOptinTextSubscribe);
    formOptinTextDetails1 = decodeURIComponent(formOptinTextDetails1);
    formOptinTextDetails2 = decodeURIComponent(formOptinTextDetails2);
    formOptinTextContact = decodeURIComponent(formOptinTextContact);
    formOptinTextAntispam = decodeURIComponent(formOptinTextAntispam);
    formOptinTextAntispamLink = decodeURIComponent(formOptinTextAntispamLink);
    if (document.getElementById("conteneur") !== null) {
      document.getElementById("conteneur").style.display = "";
    }
    var d = document,
      recipient = "",
      data = {};
    for (var i = 0; i < d.getElementsByTagName("input").length; i++) {
      if (!d.getElementsByTagName("input")[i].hasAttribute("sb-form-input")) {
        continue;
      }
      if (
        (d.getElementsByTagName("input")[i].type === "radio" && d.getElementsByTagName("input")[i].checked) ||
        d.getElementsByTagName("input")[i].type === "text" ||
        d.getElementsByTagName("input")[i].type === "email" ||
        d.getElementsByTagName("input")[i].type === "tel" ||
        d.getElementsByTagName("input")[i].type === "hidden" ||
        (d.getElementsByTagName("input")[i].type === "number" &&
          decodeURIComponent(d.getElementsByTagName("input")[i].name) !== "day" &&
          decodeURIComponent(d.getElementsByTagName("input")[i].name) !== "month" &&
          decodeURIComponent(d.getElementsByTagName("input")[i].name) !== "year")
      ) {
        data[decodeURIComponent(d.getElementsByTagName("input")[i].name)] = d.getElementsByTagName("input")[i].value;
        query_string += query_string != "" ? "&" : "";
        query_string +=
          encodeURIComponent(decodeURIComponent(d.getElementsByTagName("input")[i].name)) +
          "=" +
          encodeURIComponent(d.getElementsByTagName("input")[i].value);
        if (d.getElementsByTagName("input")[i].type === "email") {
          recipient = d.getElementsByTagName("input")[i].value;
        }
      } else if (d.getElementsByTagName("input")[i].type == "checkbox" && d.getElementsByTagName("input")[i].checked) {
        var name = decodeURIComponent(d.getElementsByTagName("input")[i].name);
        if (!(name in data)) {
          data[name] = d.getElementsByTagName("input")[i].value;
        } else {
          var val = data[name] + ", " + d.getElementsByTagName("input")[i].value;
          data[name] = val;
        }
        if (query_string.indexOf(encodeURIComponent(decodeURIComponent(d.getElementsByTagName("input")[i].name))) != -1) {
          query_string += ", " + encodeURIComponent(d.getElementsByTagName("input")[i].value);
        } else {
          query_string += query_string != "" ? "&" : "";
          query_string +=
            encodeURIComponent(decodeURIComponent(d.getElementsByTagName("input")[i].name)) +
            "=" +
            encodeURIComponent(d.getElementsByTagName("input")[i].value);
        }
      }
    }
    var ds = d.getElementsByClassName("date");
    for (var i = 0; i < ds.length; i++) {
      var day = "",
        month = "",
        year = "",
        hour = "",
        minute = "";
      for (var j = 0; j < ds[i].getElementsByTagName("input").length; j++) {
        if (!d.getElementsByTagName("input")[i].hasAttribute("sb-form-input")) {
          continue;
        }
        if (ds[i].getElementsByTagName("input")[j].type === "number") {
          if (decodeURIComponent(ds[i].getElementsByTagName("input")[j].name) === "day") {
            day = ds[i].getElementsByTagName("input")[j].value || "";
          } else if (decodeURIComponent(ds[i].getElementsByTagName("input")[j].name) === "month") {
            month = ds[i].getElementsByTagName("input")[j].value || "";
          } else if (decodeURIComponent(ds[i].getElementsByTagName("input")[j].name) === "year") {
            year = ds[i].getElementsByTagName("input")[j].value || "";
          } else if (decodeURIComponent(ds[i].getElementsByTagName("input")[j].name) === "hour") {
            hour = ds[i].getElementsByTagName("input")[j].value || "";
          } else if (decodeURIComponent(ds[i].getElementsByTagName("input")[j].name) === "minute") {
            minute = ds[i].getElementsByTagName("input")[j].value || "";
          }
        }
      }
      var f = ds[i].getAttribute("format");
      if (f) {
        if (
          (f.search("dd") !== -1 && day === "") ||
          (f.search("MM") !== -1 && month === "") ||
          (f.search("yyyy") !== -1 && year === "") ||
          (f.search("HH") !== -1 && hour === "") ||
          (f.search("mm") !== -1 && minute === "")
        ) {
          break;
        }
        f = f.replace("dd", this.formatDate(day));
        f = f.replace("MM", this.formatDate(month));
        f = f.replace("yyyy", year);
        f = f.replace("HH", this.formatDate(hour));
        f = f.replace("mm", this.formatDate(minute));
        data[decodeURIComponent(ds[i].getAttribute("name"))] = f;
        query_string += query_string != "" ? "&" : "";
        query_string += encodeURIComponent(decodeURIComponent(ds[i].getAttribute("name"))) + "=" + encodeURIComponent(f);
      }
    }
    for (i = 0; i < d.getElementsByTagName("textarea").length; i++) {
      if (d.getElementsByTagName("textarea")[i] && d.getElementsByTagName("textarea")[i].hasAttribute("sb-form-textarea")) {
        data[decodeURIComponent(d.getElementsByTagName("textarea")[i].name)] = d
          .getElementsByTagName("textarea")
          [i].value.replace(new RegExp("\\n", "g"), " ");
        query_string += query_string != "" ? "&" : "";
        query_string +=
          encodeURIComponent(decodeURIComponent(d.getElementsByTagName("textarea")[i].name)) +
          "=" +
          encodeURIComponent(d.getElementsByTagName("textarea")[i].value.replace(new RegExp("\\n", "g"), " "));
      }
    }
    for (i = 0; i < d.getElementsByTagName("select").length; i++) {
      if (
        d.getElementsByTagName("select")[i] &&
        d.getElementsByTagName("select")[i].hasAttribute("sb-form-select") &&
        d.getElementsByTagName("select")[i].options[d.getElementsByTagName("select")[i].selectedIndex]
      ) {
        data[decodeURIComponent(d.getElementsByTagName("select")[i].name)] =
          d.getElementsByTagName("select")[i].options[d.getElementsByTagName("select")[i].selectedIndex].value;
        query_string += query_string != "" ? "&" : "";
        query_string +=
          encodeURIComponent(decodeURIComponent(d.getElementsByTagName("select")[i].name)) +
          "=" +
          encodeURIComponent(d.getElementsByTagName("select")[i].options[d.getElementsByTagName("select")[i].selectedIndex].value);
      }
    }
    if (actionOptin !== "true") {
      var div = document.createElement("div");
      div.innerHTML = formUrl;
      var xhr = this.createXhrObject();
      xhr.open("POST", div.firstChild.nodeValue, !0);
      xhr.setRequestHeader("AccountId", accountId);
      var json = JSON.stringify(data);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status >= 200 && xhr.status < 400) {
            if (actionType === "message") {
              const emailToPost = document.getElementById("input-email").value;
              plausible("Inscription Newsletter");
              document.getElementById("sb_form").style.borderRadius = "1px";
              document.getElementById("sb_form").innerHTML = this.getMessageSuccess(actionContentTitle, actionContentMessage);
              var a = document.getElementById("sb_form");
              var p = a.parentNode;
              while (p.firstChild) {
                p.removeChild(p.firstChild);
              }
              p.appendChild(a);
              $.post("/confirm-newsletter-inscription", { email: emailToPost });
            } else {
              aLink = this.decodeURLComponent(actionLink);
              var decodeURL = decodeURIComponent(aLink);
              var params;
              if (decodeURL.indexOf("?") !== -1) {
                var qStr = decodeURL.substring(decodeURL.indexOf("?") + 1);
                params = this.parseQueryString(qStr);
              }
              var url = aLink;
              url += params && Object.keys(params).length > 0 ? "&" : "?";
              url += query_string;
              window.location.replace(url);
            }
          } else if (xhr.status === 500 && JSON.parse(xhr.responseText).message.toLowerCase().indexOf("lock") !== -1) {
            this.displayErrorEditing(resUrl, actionErrorTitle, formEditErrorMessage);
          } else if (actionType === "message") {
            this.displayError(resUrl, actionErrorTitle, actionErrorMessage);
          } else {
            var aLink = this.decodeURLComponent(actionLink);
            window.location.replace(aLink);
          }
        }
      };
      document.getElementById("submitInput").disabled = !0;
      document.getElementsByClassName("loader")[0].style.display = "";
      xhr.send(json);
    } else {
      var recipientFields = "";
      var urlRedirection = "";
      var div = document.createElement("div");
      div.innerHTML = actionLink;
      var params = "";
      var decodeURL = decodeURIComponent(actionLink);
      var qStr = decodeURL.indexOf("?") !== -1 ? decodeURL.substring(decodeURL.indexOf("?") + 1) : "";
      var userLink = document.createElement("a");
      userLink.setAttribute("href", decodeURIComponent(actionLink));
      var userLinkParams = this.parseQueryString(qStr);
      if (userLinkParams !== undefined) {
        var pe = this.entries(userLinkParams);
        for (var i in pe) {
          params += params != "" && query_string.slice(-1) != "&" ? "&" : "";
          params += "sb_user_" + pe[i][0] + "=" + pe[i][1];
        }
      }
      var pe = this.entries(data);
      for (var i in pe) {
        params += params != "" && params.slice(-1) != "&" ? "&" : "";
        params += pe[i][0] + "=" + pe[i][1];
        recipientFields += "&" + pe[i][0] + "=" + pe[i][1];
      }
      var pathname = "";
      if (userLink.pathname !== "") {
        pathname = userLink.pathname === "/" ? "" : userLink.pathname;
        pathname = pathname !== "" ? (pathname[0] !== "/" ? "/" + pathname : pathname) : "";
      }
      params += userLink.href !== "" ? "&sb_userlink=" + userLink.protocol + "//" + userLink.hostname + pathname : "";
      urlRedirection = userLink.href !== "" ? userLink.protocol + "//" + userLink.hostname + pathname : "";
      var urlConf = resUrl + "/validation.html?accountId" + encodeURIComponent("=" + accountId + "&sb_api_url=" + formUrl + "&" + params);
      var xhr = this.createXhrObject();
      var optinUrl = resUrl;
      if (resUrl && optinUrl.slice(-1) !== "/") {
        optinUrl += "/";
      }
      xhr.open("GET", optinUrl + "optin.html", !0);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status >= 200 && xhr.status < 400) {
            var sendXhr = this.createXhrObject();
            var div = document.createElement("div");
            div.innerHTML = formOptinUrl;
            var sendUrl = div.firstChild.nodeValue;
            sendXhr.open("POST", sendUrl, !0);
            sendXhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            if (formApiUser && formApiKey) {
              sendXhr.setRequestHeader("X-Tipimail-ApiUser", formApiUser);
              sendXhr.setRequestHeader("X-Tipimail-ApiKey", formApiKey);
            }
            div.innerHTML = actionAlias;
            var alias = div.firstChild.nodeValue;
            div.innerHTML = actionSubject;
            var subj = div.firstChild.nodeValue;
            var sendData = {
              to: [
                {
                  address: recipient,
                },
              ],
              msg: {
                from: {
                  personalName: alias,
                  address: actionRecipient + "@optin.sbcform.com",
                },
                subject: alias + " : " + subj,
                html: xhr.responseText,
              },
              headers: {
                "X-TM-SUB": [
                  {
                    email: recipient,
                    values: {
                      company: alias,
                      subject: subj,
                      url_conf: urlConf,
                      email_admin: actionReply,
                      subscribe: formOptinTextSubscribe,
                      details_1: formOptinTextDetails1,
                      details_2: formOptinTextDetails2,
                      contact: formOptinTextContact,
                      antispam: formOptinTextAntispam,
                      antispam_link: formOptinTextAntispamLink,
                      antispam_url: formOptinTextAntispamUrl,
                    },
                  },
                ],
              },
            };
            if (formOptinUrl.indexOf("/optin") !== -1) {
              var formId = formOptinTextSubscribe;
              var lang = formOptinTextDetails1;
              var product = formOptinTextDetails2;
              sendData = {
                recipientEmail: recipient,
                replyEmail: actionReply,
                senderName: alias,
                senderEmailName: actionRecipient,
                urlValidation: urlConf,
                subject: subj,
                lang: lang,
                product: product,
              };
              if (formId !== "-") {
                sendData = {
                  accountId: accountId,
                  formId: formId,
                  recipientEmail: recipient,
                  recipientFields: recipientFields,
                };
                if (actionLink === "") {
                  sendData.urlRedirection = urlRedirection;
                }
              }
            }
            var sendJson = JSON.stringify(sendData);
            sendXhr.onreadystatechange = () => {
              if (sendXhr.status >= 200 && sendXhr.status < 400) {
                document.getElementById("sb_form").style.borderRadius = "1px";
                document.getElementById("sb_form").innerHTML = this.getMessageOptin(actionEmailTitle, actionEmailMessage);
                var a = document.getElementById("sb_form");
                var p = a.parentNode;
                while (p.firstChild) {
                  p.removeChild(p.firstChild);
                }
                p.appendChild(a);
              } else {
                this.displayError(resUrl, actionErrorTitle, actionErrorMessage);
              }
            };
            sendXhr.send(sendJson);
          } else {
            this.displayError(resUrl, actionErrorTitle, actionErrorMessage);
          }
        }
      };
      document.getElementById("submitInput").disabled = "disabled";
      document.getElementById("sb_form").disabled = "disabled";
      document.getElementsByClassName("loader")[0].style.display = "";
      xhr.send(null);
    }
  }

  htmlDecode(input) {
    var e = document.createElement("textarea");
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  validate() {
    var data = {},
      query_string = "",
      userLink = "",
      userLinkParams,
      accountId = "";
    var location_search = decodeURIComponent(document.location.search);
    location_search = location_search.replace("?listID=", "&listID=");
    var params = this.parseQueryString(location_search);
    if (params !== undefined) {
      userLink = params.sb_userlink ? params.sb_userlink : "";
      accountId = params.accountId;
      apiUrl = params.sb_api_url;
      if (params.listID && params.formID && params.timezone) {
        apiUrl = apiUrl + "?listID=" + params.listID + "&formID=" + params.formID + "&timezone=" + params.timezone;
      }
      delete params.sb_userlink;
      delete params.accountId;
      delete params.sb_api_url;
      var pe = this.entries(params);
      for (var i in pe) {
        if (!pe[i][0].startsWith("sb_user_")) {
          data[pe[i][0]] = pe[i][1];
          query_string += query_string != "" && query_string.slice(-1) != "&" ? "&" : "";
          query_string += pe[i][0] + "=" + pe[i][1];
        } else {
          query_string += query_string != "" && query_string.slice(-1) != "&" ? "&" : "";
          query_string += pe[i][0].replace("sb_user_", "") + "=" + pe[i][1];
        }
      }
      userLink += "?" + query_string;
      var div = document.createElement("div");
      div.innerHTML = apiUrl;
      var xhr = this.createXhrObject();
      xhr.open("POST", div.firstChild.nodeValue, !0);
      xhr.setRequestHeader("AccountId", accountId);
      var json = JSON.stringify(data);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          var success = xhr.status >= 200 && xhr.status < 400;
          window.location.replace(userLink + "&success=" + success);
        }
      };
      xhr.send(json);
    }
  }

  entries(obj) {
    if (!Object.entries) {
      var ownProps = Object.keys(obj),
        i = ownProps.length,
        resArray = new Array(i);
      while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

      return resArray;
    }
    return Object.entries(obj);
  }

  formatDate(n) {
    if (n.length == 1) {
      return "0" + n;
    }
    return n;
  }

  decodeURLComponent(url) {
    var div = document.createElement("div");
    div.innerHTML = url;
    return div.firstChild.nodeValue;
  }

  displayError(resUrl, actionErrorTitle, actionErrorMessage) {
    document.getElementById("sb_form").style.borderRadius = "1px";
    document.getElementById("sb_form").innerHTML = this.getMessageError(resUrl, actionErrorTitle, actionErrorMessage);
    var a = document.getElementById("sb_form");
    var p = a.parentNode;
    while (p.firstChild) {
      p.removeChild(p.firstChild);
    }
    p.appendChild(a);
  }

  displayErrorEditing(resUrl, actionErrorTitle, formEditErrorMessage) {
    document.getElementById("sb_form").style.borderRadius = "1px";
    document.getElementById("sb_form").innerHTML = this.getMessageMaintenanceError(resUrl, actionErrorTitle, formEditErrorMessage);
    var a = document.getElementById("sb_form");
    var p = a.parentNode;
    while (p.firstChild) {
      p.removeChild(p.firstChild);
    }
    p.appendChild(a);
  }

  createXhrObject() {
    if (window.XMLHttpRequest) return new XMLHttpRequest();
    if (window.ActiveXObject) {
      var names = ["Msxml2.XMLHTTP.6.0", "Msxml2.XMLHTTP.3.0", "Msxml2.XMLHTTP", "Microsoft.XMLHTTP"];
      for (var i in names) {
        try {
          return new ActiveXObject(names[i]);
        } catch (e) {}
      }
    }
    return null;
  }

  parseQueryString(queryString) {
    let qString = queryString;
    if (!qString) {
      return undefined;
    }
    if (qString[0] === "?") {
      qString = qString.slice(1, qString.length);
    }
    const params = {};
    let queries = qString.split("&");
    let l = queries.length;
    let temp = [];
    for (let i = 0; i < l; i++) {
      temp = queries[i].split("=");
      params[temp[0]] = temp[1];
    }
    return params;
  }

  updateRequirements(name) {
    var checkboxesGroup = document.getElementsByName(name);
    var isChecked = false;
    for (var i = 0; i < checkboxesGroup.length; i++) {
      if (checkboxesGroup[i].checked) {
        isChecked = true;
        break;
      }
    }
    for (var i = 0; i < checkboxesGroup.length; i++) {
      checkboxesGroup[i].required = !isChecked;
    }
  }

  getMessageSuccess(actionContentTitle, actionContentMessage) {
    return `
    <div class="default__text default__message default__message--success">
      <div class="default__message__title">${actionContentTitle}</div>
      <div class="default__message__text">${actionContentMessage}</div>
    </div>`;
  }

  getMessageOptin(actionEmailTitle, actionEmailMessage) {
    return `
    <div class="default__text default__message default__message--success-optin">
      <div class="default__message__title">${actionEmailTitle}</div>
      <div class="default__message__text">${actionEmailMessage}</div>
    </div>`;
  }

  getMessageError(resUrl, actionErrorTitle, actionErrorMessage) {
    return `
    <div class="default__text default__message default__message--error">
      <div class="default__message__title">${actionErrorTitle}</div>
      <div class="default__message__text">${actionErrorMessage}</div>
    </div>`;
  }

  getMessageMaintenanceError(resUrl, actionErrorTitle, formEditErrorMessage) {
    return `
    <div class="default__text default__message default__message--maintenance">
      <div class="default__message__title">${actionErrorTitle}</div>
      <div class="default__message__text">${formEditErrorMessage}</div>
    </div>`;
  }
}
