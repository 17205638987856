import Flickity from "flickity";
import Lazy from "./lazy";

export default class Slideshow {
  constructor() {
    this.initSlideshow();
  }

  initSlideshow() {
    var slideOptions = {
      cellSelector: ".js-slide",
      cellAlign: "left",
      setGallerySize: true,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      percentPosition: false,
      draggable: true,
      selectedAttraction: 0.1,
      friction: 0.6,
      lazyLoad: true,
    };

    $(".js-slideshow").each((i, el) => {
      if ($(el).hasClass("js-slideshowMobile")) {
        slideOptions.watchCSS = true;
      }
      this.initFlickity(el, slideOptions);
      this.initImagesToZoom(el);

      $(window).on("resize", () => {
        this.initImagesToZoom(el);
      });
    });

    $(".js-slide").on("click", (e) => {
      const image = $(e.currentTarget);
      if ($(image).hasClass("js-imageToZoom")) {
        if ($(image).parents(".js-fixedContainer").hasClass("active") || $(image).parents(".js-fixedContainer").hasClass("open")) {
          this.zoomImage(image);
        }
      }
    });
  }

  initImagesToZoom(el, index = 0) {
    const slides = $(el).find(".js-slide");
    const first = $(el).find(".js-slide.is-selected").length ? $(el).find(".js-slide.is-selected") : $(slides).eq(index);
    $(slides).removeClass("js-imageToZoom");
    $(first).addClass("js-imageToZoom");
    if (window.matchMedia("(min-width: 900px)").matches) {
      const length = $(slides).length;
      const index = $(slides).index(first);
      const next = index + 1 < length ? index + 1 : 0;
      $(slides).eq(next).addClass("js-imageToZoom");
    }
  }

  initFlickity(element, options) {
    const carousel = new Flickity($(element)[0], options);

    carousel.on("staticClick", (event, pointer, cellElement, cellIndex) => {
      carousel.resize();
      const container = $(element).parents(".js-fixedContainer");
      if ($(container).hasClass("active") || $(container).hasClass("open")) {
        if (!$(cellElement).hasClass("js-imageToZoom")) carousel.next(true);
      }
    });

    carousel.on("settle", (index) => {
      this.initImagesToZoom(element, index);
    });
  }

  zoomImage(selectedSlide) {
    const image = $(selectedSlide).find("img").attr("src");
    $(".js-main").append(`<div class="zoom js-zoom"><img class="zoom__image" src="${image}"></div>`);
    this.initZoomClick();
  }

  initZoomClick() {
    $(".js-zoom").on("click", () => {
      $(".js-zoom").remove();
    });
  }
}
