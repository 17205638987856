import bez from "jquery-bez";
import Toggle from "./toggle";
import Website from "./website";
import Slideshow from "./slideshow";
import Fixed from "./fixed";
import Readmore from "./readmore";
import Marquee from "./marquee";
import Filter from "./filter";
import Player from "./player";
import Newsletter from "./newsletter";
import Cursor from "./cursor";
import Lazy from "./lazy";
import Tickets from "./tickets";

export default class Ajax {
  constructor() {
    this.nav = null;
    this.href = null;
    this.meta = null;
    this.initClick();
  }

  initClick() {
    $("a:not([download]):not([target='_blank']):not(.no-ajax)")
      .off("click")
      .on("click", (e) => {
        const link = $(e.currentTarget);
        if (link[0].host === window.location.host) {
          this.href = $(link).attr("href");
          if ($(e.currentTarget).hasClass("js-changeMonth")) {
            this.loadMonth();
          } else {
            this.loadDefault();
          }
          this.prev = window.location.href;
          e.preventDefault();
        }
      });

    $(".js-homeSearchForm")
      .off("submit")
      .on("submit", (e) => {
        const input = $(e.currentTarget).find("input").val();
        this.href = $(e.currentTarget).attr("action") + "?q=" + input;
        this.loadDefault();
        e.preventDefault();
      });

    $(window)
      .off("popstate")
      .on("popstate", (e) => {
        const state = e.originalEvent.state;
        if (state !== null) {
          this.href = state.url;
          this.loadDefault();
        } else {
          window.location.reload();
        }
      });
  }

  loadDefault() {
    const transition = this.startTransition($(".js-content"));
    const ajax = $.get(this.href);

    $.when(ajax, transition)
      .done((data) => {
        this.id = $(data[0]).find(".js-content").attr("id");
        this.meta = $(data[0]);
        this.nav = $(data[0]).find(".js-headerNav");
        this.hasBlackBackground = !!$(data[0]).find(".js-content[data-darkmode]").length;
        this.html = $(data[0]).find(".js-content").html();
        this.changeBackground = this.hasBlackBackground != $("html").hasClass("darkmode");
      })
      .then(() => {
        $(".js-content").html(this.html).attr("id", this.id);
        $(window).scrollTop(0);
        this.updateBackground();
        this.updateMeta();
        this.changeState();
        this.checkMenu();
        this.endTransition($(".js-content"));
      });
  }

  loadMonth() {
    const transition = this.startTransition($(".js-agendaInner"));
    const ajax = $.get(this.href);

    $.when(ajax, transition)
      .done((data) => {
        this.html = $(data[0]).find(".js-agendaInner").html();
        this.month = $(data[0]).find(".js-agendaSlideshowMonth").html();
        this.year = $(data[0]).find(".js-agendaSlideshowYear").html();
      })
      .then(() => {
        $(".js-agendaInner").html(this.html);
        $(".js-agendaSlideshowMonth").html(this.month);
        $(".js-agendaSlideshowYear").html(this.year);
        this.changeState();
        this.endTransition($(".js-agendaInner"));
      });
  }

  startTransition(el) {
    $("html, body").css({ pointerEvents: "none" });
    return $(el).animate({ opacity: 0 }, 500, $.bez([0.7, 0, 0.3, 1]));
  }

  endTransition(el) {
    const timeout = this.changeBackground ? 300 : 0;
    if (this.changeBackground) this.updateBackground();
    setTimeout(() => {
      return $(el)
        .animate({ opacity: 1 }, 500, $.bez([0.7, 0, 0.3, 1]))
        .promise()
        .done(() => {
          $("html, body").css({ pointerEvents: "" });
          this.initFunctions();
        });
    }, timeout);
  }

  checkMenu() {
    $(".js-headerLink, .js-headerItem, .js-headerLinkWithSub").each((i, el) => {
      const classlist = $(this.nav).find(".js-headerLink, .js-headerItem, .js-headerLinkWithSub").eq(i).attr("class");
      $(el).attr("class", classlist);
    });
  }

  initFunctions() {
    new Website();
    new Lazy();
    new Cursor();
    new Toggle();
    new Slideshow();
    new Fixed();
    new Readmore();
    new Filter();
    new Player();
    new Ajax();
    new Newsletter();
    new Tickets();

    $(".js-marquee").each((i, el) => {
      new Marquee(el);
    });
  }

  changeState() {
    const href = this.href;
    if (window.location.href !== href) {
      const location = href;
      history.pushState({ url: location }, null, location);
    }
  }

  updateBackground() {
    if (this.hasBlackBackground) {
      $("html").addClass("darkmode");
    } else {
      $("html").removeClass("darkmode");
    }
  }

  updateMeta() {
    const meta = this.meta;
    const title = $(meta).filter("title").html();
    const description = $(meta).filter('meta[name="description"]').attr("content");
    const image = $(meta).filter('meta[name="twitter:image"]').attr("content");
    $("title").html(title);
    $('meta[property="og:title"], meta[name="twitter:text:title"], meta[name="twitter:title"]').attr("content", title);
    $('meta[name="description"], meta[name="twitter:description"], meta[property="og:description"]').attr("content", description);
    $('meta[property="og:image"], meta[name="twitter:image"]').attr("content", image);
  }
}
