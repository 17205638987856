import mjml2html from "mjml-browser";

export default class Mail {
  constructor() {
    this.init();
  }

  init() {
    if (!document.querySelector(".js-mail")) return;
    fetch(window.location.href + ".json")
      .then((response) => response.json())
      .then((data) => {
        const mjml = data.mjml;
        console.log(mjml);
        const compiledMJML = mjml2html(mjml, { minify: true });
        const html = compiledMJML.html;

        const file = new File([html], "newsletter.html", {
          type: "text/html",
        });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(file);
        link.download = "newsletter.html";
        link.click();
      });
  }
}
