export default class Header {
  constructor() {
    this.delay = 80;
    this.titleTimeout = null;

    $(".js-headerLinkWithSub").on("click", (e) => {
      const container = $(e.currentTarget).parent();
      if ($(container).hasClass("open")) {
        this.hideSubmenu(container);
      } else {
        this.showSubmenu(container);
      }
    });

    $(".js-siteTitle")
      .on("mouseenter", (e) => {
        if ($("html").hasClass("no-touch")) {
          clearTimeout(this.titleTimeout);
          if ($(e.currentTarget).hasClass("js-titleItem")) $(".js-titleText").addClass("open");
        }
      })
      .on("mouseleave", () => {
        if ($("html").hasClass("no-touch")) {
          this.titleTimeout = setTimeout(() => {
            $(".js-titleText").removeClass("open");
          }, 3000);
        }
      });
  }

  showSubmenu(element) {
    const sub = $(element).find(".js-headerLink").not(".active");
    const length = $(sub).length;
    if (length > 0) {
      $(sub).each((i, el) => {
        setTimeout(() => {
          $(el).addClass("active");
        }, this.delay * i);
      });
      setTimeout(() => {
        $(element).addClass("open");
      }, this.delay * length);
    }
  }

  hideSubmenu(element) {
    const sub = $(element).find(".js-headerLink.active");
    const length = $(sub).length;
    if (length > 0) {
      $(element).removeClass("open");
      $($(sub).get().reverse()).each((i, el) => {
        setTimeout(() => {
          $(el).removeClass("active");
        }, this.delay * i);
      });
    }
  }
}
