import bez from "jquery-bez";

export default class Fixed {
  constructor() {
    this.height = window.matchMedia("(min-width: 810px)").matches ? 45 : 41;

    if ($(".js-fixedContainer").length > 0) {
      this.initFixed();
    }
  }

  initFixed() {
    this.fixedRAF = requestAnimationFrame(this.createFixed.bind(this));
    setTimeout(() => {
      $(".js-fixedContainerOuter").css({ opacity: 1, pointerEvents: "initial" });
    }, 300);

    $(".js-fixedContainer").on("click", (e) => {
      if (!$(e.currentTarget).hasClass("active")) {
        if (!$(e.target).parents().hasClass("js-fixedContainerOut") && !$(e.target).hasClass("js-fixedContainerOut")) {
          this.openFixed(e.currentTarget);
        }
      }
    });

    $(".js-fixedContainerOut").on("click", (e) => {
      this.closeFixed();
    });

    $(".js-eventOpenImages").on("click", () => {
      this.openFixed(".js-fixedContainer");
    });
  }

  createFixed() {
    if ($(".js-fixedContainer").length > 0) {
      const top = Math.round($(".js-fixedContainerOuter")[0].getBoundingClientRect().top - $(window).innerHeight() + this.height);

      if (!$(".js-fixedContainer").hasClass("open")) {
        if (top <= 0) {
          $(".js-fixedContainer").addClass("active").removeClass("js-hoverLink");
        } else {
          $(".js-fixedContainer").removeClass("active").addClass("js-hoverLink");
        }
      }

      this.sizeFixedOuter();
    }
    this.fixedRAF = requestAnimationFrame(this.createFixed.bind(this));
  }

  sizeFixedOuter() {
    const height = $(".js-fixedContainer").outerHeight();
    $(".js-fixedContainerOuter").css({ height: height });
  }

  openFixed(fixed) {
    $(fixed)
      .animate({ top: 0 }, 300, $.bez([0.7, 0, 0.3, 1]))
      .addClass("open")
      .removeClass("js-hoverLink");
    $("html, body").addClass("with__fixed");
  }

  closeFixed() {
    const top = $(window).outerHeight() - this.height;
    $(".js-fixedContainer.open")
      .animate({ top }, 300, $.bez([0.7, 0, 0.3, 1]), () => {
        $(".js-fixedContainer").removeAttr("style");
      })
      .removeClass("open")
      .addClass("js-hoverLink");
    $("html, body").removeClass("with__fixed");
  }
}
