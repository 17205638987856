export default class Variables {
  constructor() {
    window.player = null;
    window.playerIsPlaying = null;
    window.mousePosition = { x: 0, y: 0 };
    window.cursorPosition = { x: 0, y: 0 };
    window.hoverContext = null;
    window.transitioning = null;
    window.cursorStyle = null;
    window.cursorRAF = null;
  }
}
