import Marquee from "./marquee";

export default class Player {
  constructor() {
    this.initPlayer();
    this.closePlayer();
    this.clickProgress();
    this.nextTrack();
  }

  initPlayer() {
    $(".js-eventPlayer")
      .off("click")
      .on("click", (e) => {
        if (window.player && window.player.id == $(e.currentTarget).data("id")) {
          if (!window.playerIsPlaying) {
            this.playAudio(e.currentTarget);
          } else {
            this.pauseAudio();
          }
        } else {
          if (window.player) {
            window.player.audio.pause();
            window.player.audio.currentTime = 0;
            $(".js-radioPlay, .js-eventPlayer").removeClass("playing");
          }
          this.getPlayer($(e.currentTarget).data("href"));
        }
      });

    $(".js-radioPlay")
      .off("click")
      .on("click", (e) => {
        if (!window.playerIsPlaying) {
          this.playAudio(e.currentTarget);
        } else {
          this.pauseAudio();
        }
      });
  }

  closePlayer() {
    $(".js-radioClose")
      .off("click")
      .on("click", (e) => {
        this.removePlayer();
      });
  }

  removePlayer() {
    this.pauseAudio();
    $(".js-radioPlay, .js-eventPlayer").removeClass("playing");
    $(".js-radioContainer").removeClass("ready");
    setTimeout(() => {
      $(".js-radioProgress").css("--progress", "0%");
      $(".js-radioTimer").html("00:00");
      window.player = null;
    }, 300);
  }

  playAudio(button) {
    if (!window.player) {
      this.getPlayer($(button).data("href"));
    } else {
      window.player.audio.play();
      window.playerIsPlaying = true;
      window.progress = requestAnimationFrame(this.updateProgress.bind(this));
      $(".js-radioPlay").addClass("playing");
      $(`[data-id="${window.player.id}"]`).addClass("playing");
    }
  }

  pauseAudio() {
    if (window.player) {
      window.player.audio.pause();
    }
    window.playerIsPlaying = false;
    cancelAnimationFrame(window.progress);
    $(".js-radioPlay, .js-eventPlayer").removeClass("playing");
  }

  updateProgress() {
    const currentTime = window.player.audio.currentTime;
    const duration = window.player.audio.duration;
    const percent = (currentTime / duration) * 100;
    $(".js-radioProgress").css("--progress", `${percent}%`);
    $(".js-radioTimer").html(
      `${Math.floor(currentTime / 60) < 10 ? "0" + Math.floor(currentTime / 60) : Math.floor(currentTime / 60)}:${
        Math.floor(currentTime % 60) < 10 ? "0" + Math.floor(currentTime % 60) : Math.floor(currentTime % 60)
      }`
    );
    window.progress = requestAnimationFrame(this.updateProgress.bind(this));
  }

  clickProgress() {
    $(".js-radioProgress")
      .off("click")
      .on("click", (e) => {
        const duration = window.player.audio.duration;
        const percent = (e.offsetX / $(".js-radioProgress").width()) * 100;
        const currentTime = (duration / 100) * percent;
        window.player.audio.currentTime = currentTime;
      });
  }

  nextTrack() {
    $(".js-radioNext")
      .off("click")
      .on("click", (e) => {
        this.goToNextTrack();
      });
  }

  goToNextTrack() {
    const href = $(".js-radioNext").data("href");
    if (window.player) {
      window.player.audio.pause();
      window.player.audio.currentTime = 0;
      $(".js-radioPlay, .js-eventPlayer").removeClass("playing");
    }
    this.getPlayer(href);
  }

  getPlayer(href) {
    $.get(`${href}.json`).then((data) => {
      $(".js-radioTitle").html(data.title).attr("href", data.url);
      $(".js-radioDuration").html(data.length);
      $(".js-radioNext").data("href", data.nextURL);
      $(".js-radioNextTitle").html(data.nextTitle);
      $(".js-radioPlay").data("href", data.track);
      $(".js-radioContainer").addClass("ready");
      window.player = {
        audio: new Audio(data.track),
        id: data.id,
      };
      window.player.audio.play();
      window.player.audio.addEventListener("ended", () => {
        this.goToNextTrack();
      });
      window.playerIsPlaying = true;
      window.progress = requestAnimationFrame(this.updateProgress.bind(this));
      new Marquee($(".js-radioContainer").find(".js-marquee"), true);
      $(".js-radioPlay").addClass("playing");
      $(`[data-id="${data.id}"]`).addClass("playing");
      plausible("Play Episode Radio Fractale", { props: { episode: data.title } });
    });
  }
}
