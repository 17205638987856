import LazyLoad from "vanilla-lazyload";

export default class Lazy {
  constructor() {
    this.init();
  }

  init() {
    const lazyLoadInstance = new LazyLoad({
      elements_selector: ".ll",
    });
  }
}
