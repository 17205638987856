export default class Marquee {
  constructor(element, autoplay = true) {
    this.element = element;
    this.autoplay = autoplay;
    if (!$(element).hasClass("marquee--init")) {
      this.value = 0;
      this.createHTML();
      this.initStyle();
      if (!this.autoplay) this.checkMarquee();
    }
    if (this.autoplay) this.marquee = requestAnimationFrame(this.initMarquee.bind(this));
  }

  createHTML() {
    $(this.element).wrapInner('<div class="js-marqueeInner marquee__inner"></div>');
    $(this.element).find(".js-marqueeInner").wrapInner('<div class="js-marqueeItem marquee__item"></div>');
    const inner = $(this.element).find(".js-marqueeInner");
    const item = $(this.element).find(".js-marqueeItem");
    const innerWidth = $(inner).outerWidth();
    const itemWidth = $(item).outerWidth();
    let index = $(item).length;
    if (itemWidth != 0) {
      let numberOfItems = Math.floor(innerWidth / itemWidth);
      if (numberOfItems < 3) numberOfItems = 3;
      while (index <= numberOfItems) {
        $(this.element).find(".js-marqueeItem").clone().appendTo($(inner));
        index++;
      }
    }
  }

  initStyle() {
    const width = $(this.element).outerWidth() * 2;
    $(this.element).addClass("marquee--init ready").css({ overflow: "hidden" });
    $(this.element).find(".js-marqueeInner").css({ display: "flex", flexDirection: "row", whiteSpace: "nowrap", width: width, height: "100%" });
    $(this.element).find(".js-marqueeItem").css({ height: "100%" });
  }

  checkMarquee() {
    $(this.element)
      .on("mouseenter", (e) => {
        this.marquee = requestAnimationFrame(this.initMarquee.bind(this));
      })
      .on("mouseleave", () => {
        cancelAnimationFrame(this.marquee);
      });
  }

  initMarquee() {
    const length = $(this.element).data("length");
    const inner = $(this.element).find(".js-marqueeInner");
    const item = $(this.element).find(".js-marqueeItem").first();
    const div = $(item).next();
    const offset = $(div).offset().left - $(this.element).offset().left;
    const val = $(item).width() / length;
    const value = offset < val ? val : this.value + val;
    const transform = value * -1;
    if ((this.autoplay && window.playerIsPlaying) || !this.autoplay) {
      $(inner).css({ transform: `translateX(${transform}px)` });
      this.value = value;
    }

    this.marquee = requestAnimationFrame(this.initMarquee.bind(this));
  }
}
